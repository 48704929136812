<ng-template #copyIcon>
    <a role="button"
       *ngIf="data"
       [ngbTooltip]="tooltipText"
       [container]="'body'"
       class="d-inline-block px-1"
       [appCopyClipboard]="data"
       (copied)="toastrService.successNotify('Copied to clipboard')"
    >
        <fa-icon [icon]="'clipboard'"></fa-icon>
    </a>
</ng-template>

<ng-container [ngTemplateOutlet]="copyIcon" *ngIf="reverseIcon"></ng-container>
<span *ngIf="!iconOnly">
    {{previewText}}
</span>
<ng-container [ngTemplateOutlet]="copyIcon" *ngIf="!reverseIcon"></ng-container>
