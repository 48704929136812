import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { CoreService } from "../../../../core/application/services/core.service";
import { SharedModule } from "../../../shared.module";
import { ToastrService } from "../../services/toastr.service";

@Component({
    selector: 'app-copy-cell',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, NgbTooltip, SharedModule],
    templateUrl: './copy-cell.component.html',
    styleUrls: ['./copy-cell.component.scss']
})
export class CopyCellComponent
{
    @Input() data: any;
    @Input() defaultPreview?: boolean;
    @Input() preview?: string;
    @Input() reverseIcon?: boolean;
    @Input() iconOnly?: boolean;

    constructor(
        public toastrService: ToastrService,
        private coreService: CoreService,
    )
    {
    }

    get previewText(): string
    {
        switch (true) {
            case !this.data:
                return 'No info';
            case this.defaultPreview && this.data.length > 8:
                return `${this.data.slice(0, 4)}...${this.data.slice(-4)}`;
            case !!this.preview:
                return this.preview;
            case this.data.length <= 8 || !this.defaultPreview:
                return this.data;
        }
    }

    get tooltipText(): string
    {
        const result = 'click for copy %DATA% to clipboard';
        return result.replace('%DATA%', !this.coreService.isPrimitive(this.data)
            ? 'data'
            : this.data?.length > 35
                ? `${this.data.slice(0, 32)}...`
                : this.data
        );
    }
}
